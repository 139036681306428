import React, { FormEvent } from 'react'

interface ChooseConnectionFormProps {
  onChoose: (event: FormEvent<HTMLFormElement>) => void
}

export const ChooseConnectionForm = ({
  onChoose,
}: ChooseConnectionFormProps) => (
  <div className="connection-form">
    <div className="text2">Enter the name of the connection to test</div>
    <form onSubmit={onChoose}>
      <input
        className="input"
        name="connection"
        placeholder="connection-name"
        required
      />
      <button className="button" type="submit">
        Next
      </button>
    </form>
  </div>
)
