const localhostCredentialsByAuthType: {
  [authType: string]: ClientCredentials
} = {
  member: {
    domain: 'idp-dev.weworkers.io',
    clientId: 'yMY1vJJnftto3kcu8CQvhGSDR3ZMGVZO',
    redirectUri: 'http://localhost:3000',
  },
  employee: {
    domain: 'wework-corp-dev.wework-us.auth0.com',
    clientId: 'p3vyZ0kGIhiNvqvouLbJOgq3dYGo9mvw',
    redirectUri: 'http://localhost:3000',
  },
}

export interface ClientCredentials {
  domain: string
  clientId: string
  redirectUri: string
}

/*
 * {getClientCredentials} returns the correct auth0 credentials for
 * the active environment. All these are public, so no danger in exposing them
 * in source code
 */
export function getClientCredentials(hostname: string): ClientCredentials {
  const authType = process.env.REACT_APP_AUTH_TYPE || 'member'
  switch (hostname) {
    case 'localhost':
      return localhostCredentialsByAuthType[authType]
    case 'sso-test-member.staging.weworkers.io':
      return {
        domain: 'idp-staging.weworkers.io',
        clientId: 'KRuIyVX78NJTdL8QDDqVSKkfMi14jhQ0',
        redirectUri: `https://${hostname}`,
      }
    case 'sso-test-employee.staging.weworkers.io':
      return {
        domain: 'wework-corp-staging.wework-us.auth0.com',
        clientId: 'YP5TjBLo55hA792vTGDPjdCPa5Brgg7Q',
        redirectUri: `https://${hostname}`,
      }
    case 'sso-test-member.weworkers.io':
      return {
        domain: 'idp.wework.com',
        clientId: 'T2ssBC0JPWQqSd7W8snu9dSAM9gJPn3y',
        redirectUri: `https://${hostname}`,
      }
    case 'sso-test-employee.weworkers.io':
      return {
        domain: 'wework-corp-prod.wework-us.auth0.com',
        clientId: 'XZj2StrRR9OMYDSMchjDWbYHwvPW7HR3',
        redirectUri: `https://${hostname}`,
      }
    default:
      return {
        domain: 'idp.wework.com',
        clientId: 'T2ssBC0JPWQqSd7W8snu9dSAM9gJPn3y',
        redirectUri: `https://${hostname}`,
      }
  }
}
