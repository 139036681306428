import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

interface IProfileTestProps {
  reset: () => void
}

export const ProfileTest = ({ reset }: IProfileTestProps) => {
  const auth0 = useAuth0()
  const profile = auth0.user

  if (!auth0.isAuthenticated || profile === undefined) {
    const errorMessage = auth0?.error?.message
    return (
      <div className="profile-error">
        <div className="text3">Error: {errorMessage}</div>
        <button className="button" onClick={reset}>
          Reset
        </button>
      </div>
    )
  }

  const logout = () => {
    auth0.logout()
    reset()
  }

  return (
    <div className="profile">
      <div className="text3">Success!</div>
      <div className="text2">
        Hello <strong>{profile.name}</strong>, you are logged in as{' '}
        <strong>{profile.sub}</strong>
      </div>
      <div className="profile-claims">
        <div className="text1">
          Some other interesting claims you might want to see:
          {Object.entries(profile).map(([key, value]) => (
            <p key={key}>
              <strong>{key}</strong>: {value}
            </p>
          ))}
        </div>
      </div>
      <button className="button" onClick={logout}>
        Sign Out
      </button>
    </div>
  )
}
