import React, { FormEvent, useEffect, useState } from 'react'

import { ProfileTest } from './ProfileTest'
import { LoginTestForm } from './LoginTestForm'
import { AuthProvider } from './AuthProvider'
import { ChooseConnectionForm } from './ChooseConnectionForm'

import './app.css'

const connectionNameParam = 'connectionName'

export const App: React.FunctionComponent = () => {
  const [connectionName, setConnectionName] = useState<string | null>(null)
  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params[connectionNameParam]) {
      setConnectionName(params[connectionNameParam])
      setStep(1)
    }
  }, [])

  const onConnectionChoose = (event: FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    setConnectionName(event.target.connection.value)
    setStep(1)
  }

  return (
    <div className="app-container">
      <div className="text3">
        Welcome to the SSO test app. This app will help you verify that your SSO
        configuration is working.
      </div>
      {step === 0 && <ChooseConnectionForm onChoose={onConnectionChoose} />}
      {step > 0 && connectionName && (
        <AuthProvider connectionName={connectionName}>
          <>
            {step === 1 && (
              <LoginTestForm
                onLoginFinish={() => setStep(2)}
                connectionName={connectionName}
              />
            )}
            {step === 2 && (
              <ProfileTest
                reset={() => {
                  setStep(0)
                  setConnectionName(null)
                }}
              />
            )}
          </>
        </AuthProvider>
      )}
    </div>
  )
}
