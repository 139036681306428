import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { App } from './App'
import { getClientCredentials } from './config'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...getClientCredentials(window.location.hostname)}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
