import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

interface ILoginTestFormProps {
  onLoginFinish: () => void
  connectionName: string
}

export const LoginTestForm = ({
  onLoginFinish,
  connectionName,
}: ILoginTestFormProps) => {
  const auth0 = useAuth0()

  return (
    <div className="login-form">
      <div className="text2">
        Let&apos;s test out your new connection,{' '}
        <strong>{connectionName}</strong>.
      </div>
      <button
        className="button"
        onClick={() => auth0.loginWithPopup().then(onLoginFinish)}
      >
        Test Login Now
      </button>
    </div>
  )
}
