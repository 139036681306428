import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { getClientCredentials } from './config'

interface IAuthProviderProps {
  connectionName: string
  children: JSX.Element
}

export const AuthProvider = ({
  connectionName,
  children,
}: IAuthProviderProps) => {
  const config = getClientCredentials(window.location.hostname)
  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        connection: connectionName,
        redirect_uri: config.redirectUri,
      }}
    >
      {children}
    </Auth0Provider>
  )
}
